import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { Grid, Typography, Card, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
// import { ip } from "../../../service";
// import Pagination from "@material-ui/lab/Pagination";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { StatusDocument } from "../../../functions/Static";
import { Elderly } from "@material-ui/icons";
import { Link } from 'react-router-dom'
import moment from "moment-timezone";
// import moment from "moment";

const CardFooter = (props) => {
     const classes = useStyles();
     let { dataFooterDetail, dataFooterTable, language, profile, onClickCopy } = props;
     dataFooterDetail.arrDetail[0].detail = `${profile.name} ${profile.lastname} (${profile.user_id})` || "-";
     // dataFooterDetail.arrDetail[1].detail = profile.dob ? moment(profile.dob).format('DD/MM/YYYY') : "-";
     dataFooterDetail.arrDetail[1].detail = profile.dob ? moment(profile.dob).tz('Asia/Bangkok').format('DD/MM/YYYY') : "-";
     dataFooterDetail.arrDetail[2].detail = profile.phone || "-";
     dataFooterDetail.arrDetail[3].detail = profile.email || "-";
     dataFooterDetail.arrDetail[4].detail = profile.address || "-";
     dataFooterDetail.arrDetail[5].detail = profile.affiliate_url ? "https://portal.qrsfx.com/register" + profile.affiliate_url : "-";
     dataFooterDetail.arrDetail[6].detail = profile.verify_doc || 2;
     const [copied, setCopied] = useState(false);
     return (
          <Grid container justifyContent="center" alignItems="start" columnSpacing={2}>
               {/* {dataCard.map((el, i) => ( */}
               <Grid item xs={12} lg={12} marginBottom={2}>
                    <Card sx={{ py: 2, px: 3 }}>
                         <Grid mb={2}>
                              <Typography className={classes.titleStyle}>{dataFooterDetail.title}</Typography>
                              <Typography className={classes.borderSolid} />
                         </Grid>
                         {dataFooterDetail.arrDetail.map((el, i) => (
                              <Grid container rowSpacing={2}>
                                   <Grid key={i} container item xs={12} marginTop={2}>
                                        <Grid item xs={3} sm={3} md={1}>
                                             <Typography className={classes.detailTitle}>{language === "th" ? el.title_th : el.title_en}</Typography>
                                        </Grid>
                                        <Grid item xs={9} sm={9} md={11} container>
                                             <Grid item >
                                                  {i <= 5 ? (
                                                       <Typography>{el.detail}
                                                       </Typography>
                                                  ) : (
                                                       <Grid>
                                                            <Typography
                                                                 sx={{
                                                                      border: "2.2px solid",
                                                                      borderColor: StatusDocument[el.detail - 1].color,
                                                                      p: 0.5,
                                                                      borderRadius: 1.5,
                                                                      color: StatusDocument[el.detail - 1].color,
                                                                      fontWeight: 900
                                                                 }}
                                                            >
                                                                 {language === "th" ? StatusDocument[el.detail - 1].statusTh : StatusDocument[el.detail - 1].statusEn}

                                                            </Typography>
                                                       </Grid>
                                                  )}
                                             </Grid>
                                             <Grid item>
                                                  {i <= 5 ? (
                                                       null
                                                  ) : (el.detail != 1 ?
                                                       <Grid>
                                                            <Button variant="contained" size="medium" component={Link} to="/profile" style={{ backgroundColor: "#4589C6", marginLeft: 10 }}>ยืนยันตัวตน</Button>
                                                       </Grid>
                                                       : null

                                                  )}
                                             </Grid>
                                             {i === 5 && (
                                                  <Grid item xs={12} container>
                                                       <CopyToClipboard text={el.detail} onCopy={() => setCopied(true)}>
                                                            {/* {alert(el.detail)} */}
                                                            <Grid container direction="row" justifyContent="flex-end" alignContent="flex-end">
                                                                 {copied && (
                                                                      <Typography className={classes.detailStyleCopy}>
                                                                           {language === "th" ? "คัดลอกลิงค์แล้ว" : "Link is copied"}
                                                                      </Typography>
                                                                 )}
                                                                 {dataFooterDetail.arrDetail[1].detail = profile.affiliate_url ?
                                                                      <Button
                                                                           variant="contained"
                                                                           style={{
                                                                                backgroundColor: "#4589C6",
                                                                           }}
                                                                           sx={{ mt: 2 }}
                                                                      >
                                                                           Copy Affiliate Link
                                                                      </Button>
                                                                      : null}
                                                            </Grid>
                                                       </CopyToClipboard>
                                                  </Grid>
                                             )}
                                        </Grid>
                                   </Grid>
                              </Grid>
                         ))}
                    </Card>
               </Grid>
               {/* <Grid item xs={12} lg={9} marginBottom={2}>
                    <Card sx={{ py: 2, px: 3 }}>
                         <TableContainer>
                              <Table sx={{ minWidth: 400 }}>
                                   <Grid mb={2}>
                                        <Typography className={classes.titleStyle}>{dataFooterTable.title}</Typography>
                                        <Typography className={classes.borderSolid} />
                                   </Grid>
                                   {dataFooterTable.arrDetail.map((el, i) => (
                                        <Grid key={i} container item xs={12} sx={{ borderBottom: "solid 1px #ebebeb", pb: 1.5, mt: 2 }}>
                                             <Grid item xs={4}>
                                                  <Typography className={classes.detailStyle}>{el.date}</Typography>
                                             </Grid>
                                             <Grid item xs={4}>
                                                  <Typography className={classes.detailStyle}>{el.by}</Typography>
                                             </Grid>
                                             <Grid item xs={4}>
                                                  <Typography className={classes.detailStyle}>{el.activity}</Typography>
                                             </Grid>
                                        </Grid>
                                   ))}
                              </Table>
                         </TableContainer>
                    </Card>
               </Grid> */}
               {/* xs={!i ? 3 : 9} */}
               {/* <Card sx={{ py: 2, px: 3 }}>
                              <Grid mb={2}>
                                   <Typography className={classes.titleStyle}>{el.title}</Typography>
                                   <Typography className={classes.borderSolid} />
                              </Grid>
                              <TableContainer>
                                   <Table sx={{ minWidth: 400 }}>
                                        <Grid container rowSpacing={2}>
                                             {!i
                                                  ? el.arrDetail.map((ele, ind) => (
                                                         <Grid key={ind} container item xs={12}>
                                                              <Grid item xs={12}>
                                                                   <Typography className={classes.detailTitle}>{language === "th" ? ele.title_th : ele.title_en}</Typography>
                                                              </Grid>
                                                              <Grid item xs={12}>
                                                                   <Typography className={classes.detailStyle}>{ele.detail}</Typography>
                                                              </Grid>
                                                         </Grid>
                                                    ))
                                                  : el.arrDetail.map((ele, ind) => (
                                                         <Grid key={ind} container item xs={12} sx={{ borderBottom: "solid 1px #ebebeb", pb: 1.5 }}>
                                                              <Grid item xs={4}>
                                                                   <Typography className={classes.detailStyle}>{ele.date}</Typography>
                                                              </Grid>
                                                              <Grid item xs={4}>
                                                                   <Typography className={classes.detailStyle}>{ele.by}</Typography>
                                                              </Grid>
                                                              <Grid item xs={4}>
                                                                   <Typography className={classes.detailStyle}>{ele.activity}</Typography>
                                                              </Grid>
                                                         </Grid>
                                                    ))}
                                        </Grid>
                                   </Table>
                              </TableContainer>
                         </Card> */}
               {/* ))} */}
          </Grid>
     );
};
const useStyles = makeStyles((theme) => ({
     titleStyle: {
          fontSize: 16,
          color: "#000",
          fontWeight: 600,
          // [theme.breakpoints.up("sm")]: {
          //      fontSize: 28,
          // },
     },
     detailTitle: {
          fontSize: 14,
          color: "#000",
          fontWeight: 600,
          // [theme.breakpoints.up("sm")]: {
          //      fontSize: 28,
          // },
     },
     detailStyle: {
          fontSize: 14,
          color: "#000",
          fontWeight: 400,
          // [theme.breakpoints.up("sm")]: {
          //      fontSize: 28,
          // },
     },
     detailStyleCopy: {
          fontSize: 14,
          color: "#000",
          fontWeight: 400,
          alignSelf: "flex-end",
          marginRight: 5,
     },
     borderSolid: {
          width: 50,
          border: "solid 1px #4589C6",
          background: "#4589C6",
          // [theme.breakpoints.up("md")]: {
          //      width: 100,
          //      border: "solid 2px #fc0316",
          // },
     },
}));
export default withRouter(CardFooter);
