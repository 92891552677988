import React from "react";
import { withRouter } from "react-router-dom";
import { Grid, Avatar, Button, Stack, Typography } from "@material-ui/core";
import { styled } from "@material-ui/styles";
import { GridTextField } from "../../../components/index";
import avatar from "../../../assets/logo/iconavata1.png";
import { ip } from "service";
import { makeStyles } from "@material-ui/styles";
import DatePicker from "react-date-picker";

const TapProfile = (props) => {
    const { data, inputValue, checkInput, handleChange, handleSubmit, uploadProfile, profile, language, handleDob } = props;
    const classes = useStyles();

    return (
        <Grid container justifyContent="center" alignItems="start">
            <Grid item xs={12} md={4} container alignItems="center" direction="column" marginBottom={2}>
                <Avatar
                    alt="Profile"
                    src={
                        profile.profile_image
                            ? profile.profile_image.includes("profile_image")
                                ? ip + profile.profile_image
                                : profile.profile_image
                            : avatar
                    }
                    sx={{ width: 200, height: 200 }}
                />
                {/* {profile.verify_doc === 1 || profile.verify_doc === 4 ? null : ( */}
                <Stack alignItems="center">
                    <label htmlFor="contained-button-file" style={{ width: 200 }}>
                        <Input
                            // accept="image/*"
                            accept="image/png, image/jpeg"
                            id="contained-button-file"
                            type="file"
                            onChange={uploadProfile}
                        />
                        <Button variant="outlined" color="error" component="span" className="btn-style" sx={{ mt: 2, maxWidth: 200 }}>
                            {language === "th" ? "เลือกไฟล์" : "Choose File"}
                        </Button>
                    </label>
                </Stack>
                {/* )} */}
            </Grid>
            <Grid item xs={12} md={8}>
                <Grid container rowSpacing={3}>
                    <GridTextField
                        data={data}
                        state={inputValue}
                        check={checkInput}
                        handleChange={handleChange}
                        language={language}
                    // onKeyDown={(e) => {
                    //      if (e.key === "Enter") this.checkInput();
                    // }}
                    />
                    <Grid item xs={12} sm={4} textAlign="left">
                        <Typography mt={1} fontWeight="bold" fontSize="18px" color="#060606">
                            {language === "th" ? "วัน/เดือน/ปี เกิด (คริสต์ศักราช)" : "Date of birth"}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <DatePicker
                            className={"date-picker"}
                            onChange={handleDob}
                            value={inputValue.dob}
                            format="dd/MM/yyyy"
                            disabled={profile.verify_doc === 1 || profile.verify_doc === 4}
                        />
                    </Grid>
                </Grid>
            </Grid>
            {/* {profile.verify_doc === 1 || profile.verify_doc === 4 ? null : ( */}
            <Grid item xs={12} textAlign="center">
                <Button
                    variant="contained"
                    style={{ backgroundColor: "#4589C6" }}
                    className="btn-style"
                    sx={{ maxWidth: 260, mt: 10 }}
                    onClick={handleSubmit}
                    disabled={profile.verify_doc === 1 || profile.verify_doc === 4}
                >
                    {language === "th" ? "บันทึก" : "Save"}
                </Button>
                {/* <Button className={classes.root} variant="text">
                              Text
                         </Button>
                         <Button className={classes.root} variant="contained">
                              Contained
                         </Button>
                         <Button className={classes.root} variant="outlined">
                              Outlined
                         </Button> */}
            </Grid>
            {/* )} */}
        </Grid>
    );
};
const Input = styled("input")({
    display: "none",
});
// const useStyles = makeStyles((theme) => ({
//      titleStyle: {
//           fontSize: 20,
//           color: "#000",
//           textAlign: "center",
//           fontWeight: 600,
//           [theme.breakpoints.up("sm")]: {
//                fontSize: 32,
//           },
//      },
//      borderSolid: {
//           textAlign: "center",
//           width: 50,
//           border: "solid 1px #fc0316",
//           background: "#fc0316",
//           [theme.breakpoints.up("md")]: {
//                width: 100,
//                border: "solid 2px #fc0316",
//           },
//      },
//      imgStyle: {
//           objectFit: "contain",
//           height: 90,
//           [theme.breakpoints.up("md")]: {
//                height: 140,
//           },
//      },
//      fontStyleTitle: {
//           fontSize: 17,
//           color: "#000",
//           textAlign: "center",
//           marginTop: 15,
//           fontWeight: 600,
//           [theme.breakpoints.up("md")]: {
//                fontSize: 28,
//           },
//      },
//      fontStyleDetail: {
//           fontSize: 12,
//           color: "#676767",
//           textAlign: "center",
//           marginTop: 10,
//           [theme.breakpoints.up("md")]: {
//                fontSize: 18,
//           },
//      },
// }));

const useStyles = makeStyles((theme) => {
    return {
        root: {
            "&.MuiButton-root": {
                border: "2px black solid",
            },
            "&.MuiButton-text": {
                color: "grey",
            },
            "&.MuiButton-contained": {
                color: "#4589C6",
                border: "1px solid #4589C6",
                backgroundColor: "#fff",
            },
            "&.MuiButton-outlined": {
                color: "#4589C6",
            },
        },
    };
});

export default withRouter(TapProfile);
